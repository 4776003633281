<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-departments'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >

          <v-col cols="14" sm="8" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="secondary" dark @click="dialog = true">
                  <v-icon color="black"> mdi-plus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add New Category
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="8">
            <v-row dense class="itemsLayout">
              <v-col lg="3" md="3" sm="3" xs="4" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="secondary"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="item in dataFiltered"
                :key="item.subDepartmentId"
                lg="3"
                md="3"
                sm="3"
                xs="4"
                class="pb-3"
              >
                <v-card height="200" width="150" class="overflow-hidden">
                  <v-img
                    v-if="!item.image"
                    class="white--text align-end"
                    gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
                    color="red"
                    width="150"
                    height="100"
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div style="margin-top: 0px; text-align: center">
                          <v-icon
                            class="text-h1"
                            color="white"
                            @click="addImage(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-image-plus
                          </v-icon>
                        </div>
                      </template>
                      <span>Add Image</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.departmentName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>

                  <v-img
                    v-if="item.image"
                    :src="$url + item.image"
                    class="white--text align-end"
                    width="150"
                    height="100"
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.departmentName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>

                  <router-link
                    v-bind:to="{
                      path:
                        '/admin/admin-departments/admin-sub-departments/' +
                        item.subDepartmentId,
                    }"
                    style="text-decoration: none"
                  >
                    <v-card-text style="cursor: pointer" class="black--text">
                      <div class="headerClass">
                        <v-icon style="font-size: 20px" class="mb-1">
                          mdi-food-fork-drink
                        </v-icon>

                        {{ item.subDepartmentName }}
                      </div>

                      <div>
                        <v-chip
                          label
                          color="green"
                          class="white--text"
                          style="height: 25px"
                          v-if="item.isInactive === false"
                        >
                          Active
                        </v-chip>
                        <v-chip
                          label
                          color="red"
                          class="white--text"
                          style="height: 25px"
                          v-if="item.isInactive === true"
                        >
                          In Active
                        </v-chip>
                      </div>
                    </v-card-text>
                  </router-link>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="14" sm="12" md="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @click="changePage"
                circle
                color="black"
              ></v-pagination>
            </v-col>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <v-row style="float: right; margin-top: 10px; margin-right: 10px">
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      @click="editMainInfo()"
                      style="margin-right: 10px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-circle-edit-outline
                    </v-icon>
                  </template>
                  <span>Edit {{ mainInfo.departmentName }}</span>
                </v-tooltip>

                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <v-icon
                        color="red"
                        @click="deleteMainInfo()"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete-circle
                      </v-icon>
                    </div>
                  </template>
                  <span>Delete {{ mainInfo.departmentName }}</span>
                </v-tooltip>
              </v-row>
              <v-card-title style="font-size: 20px">
                Department Info
              </v-card-title>
              <v-card-title
                style="
                  font-size: 18px;
                  font-weight: bold;
                  text-transform: uppercase;
                "
              >
                {{ mainInfo.departmentName }}
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                No of Sub-Departments :
                {{ countItems }}
              </v-card-text>
              <v-card-text>
                Note :
                {{ mainInfo.note }}
              </v-card-text>

              <v-card-text>
                <v-chip
                  label
                  color="green"
                  class="white--text"
                  v-if="mainInfo.isInactive === false"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  color="red"
                  class="white--text"
                  v-if="mainInfo.isInactive === true"
                >
                  In Active
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-col cols="14" sm="8" md="2" class="float-right mt-n15">
          <v-row dense class="mb-5 float-right">
            <v-btn color="secondary" @click="dialogTrash = true">
              <v-icon color="black"> mdi-delete-restore </v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-container>

      <v-dialog v-model="dialogViewItem" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogViewItem = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title style="font-size: 20px">
            Category Info
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editItem()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle-edit-outline
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editImage()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-image-plus
                </v-icon>
              </template>
              <span>Edit Image</span>
            </v-tooltip>
          </v-card-title>

          <v-card-title
            style="
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            {{ viewItem.subDepartmentName }}
          </v-card-title>
          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            Note :
            {{ viewItem.note }}
          </v-card-text>

          <v-card-text>
            <v-chip
              label
              color="green"
              class="white--text"
              v-if="viewItem.isInactive === false"
            >
              Active
            </v-chip>
            <v-chip
              label
              color="red"
              class="white--text"
              v-if="viewItem.isInactive === true"
            >
              In Active
            </v-chip>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="close">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>

          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.subDepartmentName"
                      label="Category Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" class="mb-n8">
                    <v-select
                      v-model="editedItem.isInactive"
                      label="Status"
                      :items="status"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col v-if="titleChange == -1" cols="12" class="mb-n8">
                    <v-file-input
                      v-model="editedItem.formFile"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      label="Image"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogImage" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogImage = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline"> Add Image</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="imageForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="viewItem.subDepartmentName"
                      label="Sub Department"
                      outlined
                      readonly
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      id="imageFile"
                      v-model="editedImage.formFile"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      label="Image"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <!-- <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn> -->
            <v-btn color="blue darken-1" text @click="dialogImage = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="addImageToDb">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="mainInfoEdit" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="mainInfoEdit = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">Edit Department</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedMainInfo.departmentName"
                      label="Department Name"
                      outlined
                      clearable
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-select
                      v-model="editedMainInfo.isInactive"
                      label="Status"
                      :items="status"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="mainInfoEdit = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEditMainInfo">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDeleteMainInfo" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ mainInfo.departmentName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashMainInfo">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-row style="float: right">
            <div>
              <v-icon color="closeButton" @click="dialogTrash = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table
            :headers="headers"
            :items="trashed"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:[`item.isInactive`]="{ item }">
              <div>
                <v-chip
                  label
                  style="color: green; width: 100%; justify-content: center"
                  v-if="item.isInactive === false"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  style="color: red; width: 100%; justify-content: center"
                  v-if="item.isInactive === true"
                >
                  In Active
                </v-chip>
              </div>
            </template>

            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="secondary" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeRestore">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to restore
            {{ editedItem.subDepartmentName }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeRestore">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ editedItem.subDepartmentName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete
            {{ editedItem.subDepartmentName }} permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 435px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(255, 255, 255);
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      dialogViewItem: false,
      viewItem: [],
      mainInfo: {},
      editedMainInfo: {},
      mainInfoEdit: false,
      dialogDeleteMainInfo: false,

      countItems: "",

      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "Sub Department Id", value: "subDepartmentId" },
        { text: "Sub Department", value: "subDepartmentName" },
        { text: "Status", value: "isInactive" },
        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],
      subDepartments: [],
      status: [
        { text: "Acitve ", value: false },
        { text: "InActive ", value: true },
      ],
      titleChange: -1,
      editedIndex: "",
      editedItem: {
        departmentId: null,
        subDepartmentName: "",
        isInactive: false,
        formFile: null,
      },
      defaultItem: {
        departmentId: null,
        subDepartmentName: "",
        isInactive: false,
        formFile: null,
      },
      departmentId: "",
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      dataFiltered: [],

      filtered: {
        orderBy: "",
        orderDirection: "desc",
        skip: 0,
        take: 7,
        searchValue: "",
        searchColumn: "",
        clubId: "",
      },
      formData: null,
      dialogImage: false,

      editedImage: {
        subDepartmentId: "",
        formFile: "",
      },
      imageFormData: null,
    };
  },

  computed: {
    formTitle() {
      return this.titleChange === -1
        ? "Add New Sub Department"
        : "Edit Sub Departmet";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPermanentDelete(val) {
      val || this.closeDelete();
    },
    dialogRestore(val) {
      val || this.closeRestore();
    },
    page: function () {
      this.changePage();
    },
  },

  created() {
    this.getDataFromApi();
    //  this.getFilteredData();
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      var currentUrl = window.location.pathname;
      let vars = currentUrl.split("/");
      vars.reverse();
      let itemId = vars[0];
      itemId = parseInt(itemId);

      this.departmentId = itemId;
      this.editedItem.departmentId = itemId;

      this.$http
        .get("/SubDepartment")
        .then((response) => {
          this.subDepartments = response.data.data.filter(
            (a) => a.departmentId === itemId
          );
          this.subDepartments = this.subDepartments.reverse();
          this.$http.get("/SubDepartment/GetTrashed").then((response) => {
            this.trashed = response.data.data.filter(
              (a) => a.departmentId === itemId
            );
          });
          this.changePage();
        })
        .catch((error) => {
          console.log("Error");
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });

      this.$http.get(`/Department/${itemId}`).then((response) => {
        this.mainInfo = response.data.data;
      });
    },

    // getFilteredData() {
    //   this.$http
    //     .post("/SubDepartment/GetAllByFilter", this.filtered)
    //     .then(response => {
    //       this.dataFiltered = response.data.data;
    //     });
    // },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      // var diff = this.page - 1;
      // this.filtered.skip = this.start + this.end * diff;
      // this.getFilteredData();

      var count = this.subDepartments.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.subDepartments.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.countItems = this.subDepartments.length;
      this.isLoading = false;
    },

    viewEditItem(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);
      this.dialogViewItem = true;
    },

    editItem() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.titleChange = 0;
      this.dialog = true;
    },
    editMainInfo() {
      this.editedMainInfo = Object.assign({}, this.mainInfo);
      this.mainInfoEdit = true;
    },
    deleteMainInfo() {
      this.dialogDeleteMainInfo = true;
    },

    deleteItem(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedIndex = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndex = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    trashItem() {
      this.$http
        .post("/SubDepartment/Trash", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight",
            });
            this.subDepartments.splice(this.editedIndex, 1);
            this.trashed.unshift(this.editedItem);
            this.updateData();
            this.closeDelete();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },

    restoreItemConfm() {
      this.$http
        .post("/SubDepartment/Restore", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndex, 1);

            this.subDepartments.unshift(response.data.data);
            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var itemId = this.editedItem.subDepartmentId;
      console.log(itemId);
      console.log("1");
      this.$http
        .delete("/SubDepartment/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted Permanently.", "Success", {
              position: "topRight",
            });
            this.trashed.splice(this.editedIndex, 1);
          }
        })

        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });

      console.log("3");
      this.closeDelete();
    },
    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.departmentId = this.departmentId;
        this.titleChange = -1;
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
    },

    closeDelete() {
      this.setDefault();
      this.dialogDeleteMainInfo = false;
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
    },

    closeRestore() {
      this.setDefault();
      this.dialogRestore = false;
    },

    validate() {
      if (this.editedItem.subDepartmentName) {
        this.createFormData();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },
    createFormData() {
      this.formData = new FormData();

      this.formData.append(
        "subDepartmentName",
        this.editedItem.subDepartmentName
      );
      this.formData.append("departmentId", this.editedItem.departmentId);
      this.formData.append("isInactive", this.editedItem.isInactive);
      this.formData.append("formFile", this.editedItem.formFile);

      // Display the key/value pairs

      for (var pair of this.formData.entries()) {
        console.log(pair[0] + ":" + pair[1]);
      }

      this.save();
    },
    save() {
      this.isLoading = true;
      if (this.titleChange === -1) {
        this.$http
          .post("/SubDepartment", this.formData)
          .then((response) => {
            if (response.data.success === true) {
              this.subDepartments.unshift(response.data.data);
              this.updateData();
              this.$toast.success(
                "subDepartment added successfully.",
                "Success",
                {
                  position: "topRight",
                }
              );
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status) {
              this.$toast.error("Adding Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
      } else {
        var itemId = this.editedItem.subDepartmentId;
        this.$http
          .put("/SubDepartment/" + itemId, this.editedItem)
          .then((response) => {
            if (response.data.success === true) {
              this.viewItem = Object.assign({}, response.data.data);
              this.subDepartments.splice(this.editedIndex, 1);
              this.subDepartments.unshift(response.data.data);
              this.updateData();
              this.$toast.success(
                "Sub Department Edited successfully.",
                "Success",
                {
                  position: "topRight",
                }
              );
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status) {
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
      }
      this.close();
    },
    saveEditMainInfo() {
      if (
        this.editedMainInfo.departmentName &&
        this.editedMainInfo.isInactive
      ) {
        var itemId = this.editedMainInfo.departmentId;
        this.isLoading = true;
        this.$http
          .put("/Department/" + itemId, this.editedMainInfo)
          .then((response) => {
            if (response.data.success === true) {
              this.mainInfo = response.data.data;
              this.isLoading = false;
              this.$toast.success(
                "Department Edited successfully.",
                "Success",
                {
                  position: "topRight",
                }
              );
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status) {
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
        this.mainInfoEdit = false;
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },
    trashMainInfo() {
      console.log(this.mainInfo);
      this.$http
        .post("/Department/Trash", this.mainInfo)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight",
            });
            this.$router.push("/admin/admin-departments");
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
    editImage() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.addImage(this.editedItem);
    },
    addImage(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);

      this.editedImage.subDepartmentId = item.subDepartmentId;
      this.editedImage.formFile = null;
      this.dialogImage = true;
      // document.getElementById("imageFile").click();
    },
    addImageToDb() {
      this.imageFormData = new FormData();

      this.imageFormData.append(
        "subDepartmentId",
        this.editedImage.subDepartmentId
      );
      this.imageFormData.append("image", this.editedImage.formFile);

      this.saveImage();
    },
    saveImage() {
      this.isLoading = true;
      this.$http
        .post("/SubDepartment/Image", this.imageFormData)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });

            this.dialogImage = false;
            this.$http.get("/SubDepartment").then((response) => {
              console.log(response);
              this.subDepartments = response.data.data.filter(
                (a) => a.departmentId === this.departmentId
              );
              this.subDepartments = this.subDepartments.reverse();

              this.changePage();
            });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status) {
            this.$toast.error("Adding Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },
  },
};
</script>
